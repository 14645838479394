<template>
    <div class="contactus">
        <div class="banner">
            <div class="container">
                <img :src="appData.about_us_image && appData.about_us_image.content" alt="" />
            </div>
        </div>
        <footer class="footer">
            <div class="container">
                <h4>{{ $t('m.10045') }}</h4>
                <div class="content">
                    <aside class="left">
                        <google-map />
                    </aside>
                    <div class="right">
                        <el-form :model="form" ref="form" :rules="rules" label-width="80px" label-position="top" :inline="false" size="normal">
                            <el-form-item :label="$t('m.10119')" prop="name">
                                <el-input v-model.trim="form.name" :placeholder="$t('m.10118')"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('m.10120')" prop="mobile">
                                <el-input v-model.trim="form.mobile" :placeholder="$t('m.10121')">
                                    <el-select v-model.trim="phoneCode" slot="prepend" :placeholder="$t('m.10122')">
                                        <el-option :label="'+852（' + $t('m.10123') + '）'" value="852"> </el-option>
                                        <el-option :label="'+86（' + $t('m.10124') + '）'" value="86"> </el-option>
                                        <el-option :label="'+886（' + $t('m.10125') + '）'" value="886"> </el-option>
                                        <el-option :label="'+853（' + $t('m.10126') + '）'" value="853"> </el-option>
                                    </el-select>
                                </el-input>
                            </el-form-item>
                            <el-form-item :label="$t('m.10127')" prop="company">
                                <el-input v-model.trim="form.company" :placeholder="$t('m.10128')"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('m.10129')" prop="email">
                                <el-input v-model.trim="form.email" :placeholder="$t('m.10130')"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('m.10131')" prop="notes">
                                <el-input v-model.trim="form.notes" :placeholder="$t('m.10132')"></el-input>
                            </el-form-item>
                            <el-form-item class="form-button">
                                <el-button class="button" @click="onSubmit" :disabled="isDisabled">{{ $t('m.10137') }}</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <p>{{ $t('m.10050') }}</p>
            </div>
        </footer>
        <el-dialog class="form-dialog" :visible.sync="successVisible" top="30vh" :close-on-click-modal="true" :before-close="handleClose">
            <img class="image" src="@/assets/imgs/success.png" alt="" />
            <p class="text1">{{ $t('m.10133') }}</p>
            <p class="text2">{{ $t('m.10134') }}</p>
        </el-dialog>
    </div>
</template>

<script>
import googleMap from '@/components/googleMap.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        googleMap,
    },
    data() {
        return {
            // 表单绑定数据
            form: {
                name: '',
                company: '',
                mobile: '',
                email: '',
                notes: '',
                source: 3,
                platform: 'pc',
            },
            // 表单校验规则
            rules: {
                name: [
                    { required: true, message: this.$t('m.10118') },
                    { validator: this.messageNameValidator, trigger: 'blur' },
                ],
                company: [
                    { required: true, message: this.$t('m.10128') },
                    { validator: this.companyNameValidator, trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: this.$t('m.10121') },
                    { validator: this.phoneValidator, trigger: 'blur' },
                ],
                email: [
                    { required: true, message: this.$t('m.10130') },
                    { validator: this.mailboxValidator, trigger: 'blur' },
                ],
                notes: [{ required: false }],
            },
            // 区域手机code
            phoneCode: '852',
            // 提交成功的对话框是否可见
            successVisible: false,
        };
    },
    computed: {
        ...mapGetters({
            appData: 'app/appData',
        }),
        isDisabled() {
            return !(this.form.name && this.form.company && this.form.mobile && this.form.email);
        },
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        phoneValidator(rule, value, callback) {
            const reg1 = /^1[3-8][0-9]{9}$/;
            const reg2 = /^[965][0-9]{7}$/;
            const reg3 = /^6[68][0-9]{5}$/;
            const reg4 = /^09[0-9]{8}$/;
            if (value.trim() === '') {
                callback(this.$t('m.10121'));
            } else {
                if (this.phoneCode == 86) {
                    if (!reg1.test(value)) callback(this.$t('m.10135'));
                    else callback();
                } else if (this.phoneCode == 852) {
                    if (!reg2.test(value)) callback(this.$t('m.10135'));
                    else callback();
                } else if (this.phoneCode == 853) {
                    if (!reg3.test(value)) callback(this.$t('m.10135'));
                    else callback();
                } else if (this.phoneCode == 886) {
                    if (!reg4.test(value)) callback(this.$t('m.10135'));
                    else callback();
                }
            }
        },
        mailboxValidator(rule, value, callback) {
            const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
            if (value.trim() === '') {
                callback(this.$t('m.10130'));
            } else {
                if (!reg.test(value)) callback(this.$t('m.10136'));
                else callback();
            }
        },
        messageNameValidator(rule, value, callback) {
            if (value.trim() === '') {
                callback(this.$t('m.10118'));
            } else {
                callback();
            }
        },
        companyNameValidator(rule, value, callback) {
            if (value.trim() === '') {
                callback(this.$t('m.10128'));
            } else {
                callback();
            }
        },
        // 提交按钮点击
        onSubmit() {
            this.$refs.form.validate((success) => {
                if (success) {
                    this.form.serverCode = 2;
                    this.form.area_code = this.phoneCode;
                    this.form.platform = this.isMobile ? 'h5' : 'pc';
                    this.form.h5platform = this.getOS();
                    this.$api.saveContactus(this.form).then((res) => {
                        if (res.code == 200) {
                            this.successVisible = true;
                        } else {
                            this.$message.error({
                                message: res.message,
                                offset: 100,
                            });
                        }
                    });
                }
            });
        },
        // 重置表单字段
        resetForm() {
            this.$refs.form.resetFields();
        },
        // 子对话框关闭时的回调，重置表单
        handleClose() {
            this.successVisible = false;
            this.resetForm();
        },
        // 清空所有校验信息
        clearValidate() {
            this.$refs.form.clearValidate();
        },
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .contactus {
        padding-top: vv(40);
        .banner {
            width: 100%;
            .container {
                height: vv(138);
                padding: 0;
                img {
                    height: 100%;
                }
            }
        }
        .footer {
            margin-top: vv(30);
            padding-bottom: vv(10);
            background: #fafbfc;
            h4 {
                font-size: vv(15);
                font-weight: 700;
                height: vv(58);
                line-height: vv(58);
                color: $b29;
                text-align: center;
            }
            .content {
                .left {
                    width: 100%;
                    height: vv(163);
                    background-color: #fff;
                }
                .right {
                    padding: 0 vv(20);
                    /deep/ .el-form-item {
                        margin-bottom: vv(15);
                    }
                    /deep/ .el-input-group__prepend {
                        width: vv(152);
                    }
                    /deep/ .el-form-item__label {
                        padding: 0;
                    }
                    /deep/ .el-input__inner {
                        padding: 0 vv(12);
                    }
                    /deep/ .el-input__suffix {
                        right: 0;
                    }
                    .form-button {
                        text-align: right;
                        margin-top: 20px;
                        .button {
                            width: vv(100);
                            height: vv(35);
                            padding: 0;
                            &.is-disabled {
                                background: linear-gradient(135deg, #c0dfe2 0%, #9ee2e7 100%);
                            }
                            background: linear-gradient(135deg, #00b9c5 0%, #009aa4 100%);
                            ::v-deep span {
                                font-size: vv(15);
                                color: #fff;
                            }
                        }
                    }
                }
            }
            p {
                margin-top: vv(25);
                text-align: center;
                font-size: vv(10);
                color: $b9;
            }
        }
        .form-dialog {
            width: 100vw;
            text-align: center;
            border-radius: 4px;
            /deep/ .el-dialog {
                width: 80%;
            }
            .image {
                width: 96px;
                height: 88px;
            }
            .text1 {
                color: $b3;
                font-size: 28px;
                margin: 30px 0 10px;
            }
            .text2 {
                color: $gray;
                font-size: 20px;
            }
        }
    }
}
@media (min-width: 769px) {
    .contactus {
        padding-top: 108px;
        .banner {
            width: 100%;
            height: 368px;
            display: flex;
            justify-content: center;
            padding: 0 15px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .footer {
            margin-top: 60px;
            padding-bottom: 100px;
            background: #fafbfc;
            display: flex;
            justify-content: center;
            padding-left: 30px;
            padding-right: 30px;
            h4 {
                text-align: center;
                height: 116px;
                line-height: 116px;
            }
            .container {
                width: 100%;
            }
            .content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                width: 100%;
                .left {
                    width: 100%;
                    height: 636px;
                    background-color: #fff;
                }
                .right {
                    margin-top: 50px;
                    width: 100%;
                    /deep/ .el-input-group__prepend {
                        width: 200px;
                    }
                    .form-button {
                        text-align: right;
                        margin-top: 20px;
                        .button {
                            width: 148px;
                            height: 50px;
                            &.is-disabled {
                                background: linear-gradient(135deg, #c0dfe2 0%, #9ee2e7 100%);
                            }
                            background: linear-gradient(135deg, #00b9c5 0%, #009aa4 100%);
                            ::v-deep span {
                                font-size: 20px;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            p {
                display: none;
            }
        }
        .form-dialog {
            text-align: center;
            border-radius: 4px;
            /deep/ .el-dialog {
                width: 430px;
            }
            .image {
                width: 96px;
                height: 88px;
            }
            .text1 {
                color: $b3;
                font-size: 28px;
                margin: 30px 0 10px;
            }
            .text2 {
                color: $gray;
                font-size: 20px;
            }
        }
    }
}

@media (min-width: 992px) {
    .contactus {
        .container {
            width: 992px;
        }
        .footer {
            .container {
                width: 992px;
            }
            .content {
                flex-direction: row;
                .left {
                    width: 490px;
                }
                .right {
                    flex: 1;
                    margin-top: 0px;
                    margin-left: 110px;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .contactus {
        .container {
            width: 1200px;
        }
        .footer {
            .container {
                width: 1200px;
            }
        }
    }
}
</style>
